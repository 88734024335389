#video-container {
    width: 100vw;
    height: 100vh;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
